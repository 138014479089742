.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    color: var(--color-light);
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-primary-variant);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: var(--color-bg-variant);
    height: 30%;
    padding: 4rem;
    font-size: 1.2rem;
    border-radius: 4rem;
    text-align: center;
}

.popup-content .close__btn:hover {
    background-color: var(--color-bg);
    color: var(--color-light);
}

.portfolio__item-cta .btn-primary:hover {
    background: var(--color-bg-second);
    color: var(--color-bg);
}

.close__btn {
    background: var(--color-primary);
    padding: 1rem;
    border-radius: 8rem;
    margin-top: 2rem;
    border: 1px solid transparent;
    font-size: 1.2rem;
    transition: var(--transition);
}

.close__btn:hover {
    background: var(--color-bg);
    /* background: transparent; */
}



/* ================= Media Queries(Medium Devices) ============== */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ================= Media Queries(Small Devices) ============== */

@media screen and (max-wdith: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}