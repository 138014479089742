header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ============ CTA =========== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.cta a:hover {
    background: var(--color-bg-second);
    color: var(--color-bg);
}

/* ============ Header Socials =========== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials a:hover {
    color: var(--color-bg-variant);
}

/* Trailing line after the socials */
.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);

}

/* ============ My Image =========== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    /* background: var(--color-primary); */
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 4rem 4rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5 1.5rem 1.5rem 1.5rem;
}

.me img {
    width: 100%;
    height: 100%;
    display: block;
}

/* ============ Scroll Down =========== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.scroll__down:hover {
    color: var(--color-bg-variant);
}

/* ================= Media Queries(Medium Devices) ============== */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* ================= Media Queries(Small Devices) ============== */

@media screen and (max-width: 600px) {

    header {
        height: auto;
        /* Adjust the height to be auto so it can expand as needed */
        padding-top: 3rem;
        /* Optionally reduce the top padding */
    }

    .me {
        width: 90%;
        /* Adjust the width to a percentage of the screen */
        height: auto;
        /* Make height auto to maintain aspect ratio */
        position: relative;
        /* Change position to relative */
        left: 50%;
        /* Center the image */
        transform: translateX(-50%);
        /* Adjust for center alignment */
        margin-top: 2rem;
        /* Adjust top margin as needed */
        border-radius: 2rem;
        /* Optionally adjust the border-radius */
        padding: 2rem;
        /* Adjust the padding */
    }

    .me img {
        height: auto;
        /* Ensure the image maintains its aspect ratio */
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}