.bubble {
    border-radius: 50%;
    opacity: 0.6;
    animation: blob 15s infinite;
    z-index: -50;
}

@keyframes blob {

    0%,
    100% {
        transform: scale(1) translate(0px, 0px);
    }

    25% {
        transform: scale(1.2) translate(-20px, 20px);
    }

    50% {
        transform: scale(1.4) translate(30px, -30px);
    }

    75% {
        transform: scale(1.3) translate(10px, 40px);
    }
}