footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
    transition: background 0.3s ease, color 0.3s ease, padding 0.3s ease;
    /* Smooth transition for hover effects */
}

.footer__logo__hover a:hover {
    background: var(--color-bg);
    color: var(--color-light);
    padding: 0.5rem 1rem;
    /* Increase padding to enlarge the hover area */
    border-radius: 10px;
    /* Rounded corners */
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

/* Adjustments for footer__logo__hover */
.footer__logo__hover {
    display: inline-block;
    /* Make the div inline for proper alignment */
    border-radius: 10px;
    /* Rounded corners */
}


.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-light);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);

}

/* ================= Media Queries(Small Devices) ============== */

@media screen and (max-width: 600px) {

    footer {
        width: 100%;
        /* Ensure the footer takes full width */
        padding: 3rem 0;
        /* Adjust padding as needed */
        margin-top: 7rem;
        /* Keep or adjust the margin as per your design */
        box-sizing: border-box;
        /* Include padding in width calculation */
    }

    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;
        /* Ensure full width for links container */
        padding: 0;
        /* Reset any padding if necessary */
    }

    .footer__socials {
        margin-bottom: 2.6rem;
        width: 100%;
        /* Ensure full width for social links */
    }

    /* Add any other necessary styles for small devices */
}